import { createSelector } from 'reselect';
import { LOAD_STATUS_PENDING, LOAD_STATUS_STALE } from 'state-domains/constants';

import { AsyncState, AsyncStateError, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    EventActionState,
    EventsObject,
    EventsSubState,
    Job,
    JobState,
    PostsObject,
    PostsSubState,
} from '../types';

const jobState = (state: Partial<ShimState>): JobState => {
    const { job: jsJob = {} as JobState } = state || {};
    return jsJob;
};

const jobsList = createSelector(jobState, ({ jobs = {} }: JobState): Job[] => Object.values(jobs));

const eventsState = createSelector(
    jobState,
    ({
        eventsState = { items: [], count: 0, status: LOAD_STATUS_PENDING, error: null },
    }: JobState): EventsSubState => eventsState,
);

const events = createSelector(
    eventsState,
    ({ items = [] }: EventsSubState): EventsObject[] => items,
);

const eventCount = createSelector(eventsState, ({ count = 0 }: EventsSubState): number => count);

const isSearchActive = createSelector(
    jobState,
    ({ searchTerm = '' }: JobState): boolean => searchTerm !== '',
);

const postsState = createSelector(
    jobState,
    ({
        postsState = { items: [], count: 0, status: LOAD_STATUS_PENDING, error: null },
    }: JobState): PostsSubState => postsState,
);

const posts = createSelector(postsState, ({ items = [] }: PostsSubState): PostsObject[] => items);

const postsCount = createSelector(postsState, ({ count = 0 }: PostsSubState): number => count);

const eventActionState = createSelector(
    jobState,
    ({
        eventActionState = { status: LOAD_STATUS_STALE, error: null, shouldReload: false },
    }: JobState): EventActionState => eventActionState,
);

const eventActionStateError = createSelector(
    eventActionState,
    ({ error }: AsyncState): AsyncStateError => error ?? {},
);

const shouldReloadAfterEvent = createSelector(
    eventActionState,
    ({ shouldReload }: EventActionState): boolean => shouldReload,
);

export const selectors = {
    jobState,
    jobsList,
    isJobListPending: isPendingSelector(jobState),
    isJobListComplete: isCompleteSelector(jobState),
    isJobListFailed: isFailedSelector(jobState),
    isSearchActive,
    isEventsPending: isPendingSelector(eventsState),
    isEventsComplete: isCompleteSelector(eventsState),
    isEventsFailed: isFailedSelector(eventsState),
    events,
    eventCount,
    isPostsPending: isPendingSelector(postsState),
    isPostsComplete: isCompleteSelector(postsState),
    isPostsFailed: isFailedSelector(postsState),
    posts,
    postsCount,
    eventActionState,
    isEventActionPending: isPendingSelector(eventActionState),
    isEventActionComplete: isCompleteSelector(eventActionState),
    isEventActionFailed: isFailedSelector(eventActionState),
    eventActionStateError,
    shouldReloadAfterEvent,
};
